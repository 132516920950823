import { toast } from "react-toastify";

const gsm7Encode =
    /^[A-Za-z0-9 \r\n@£¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#$%&'()*+,\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\[~\]|€]*$/;
const curlyBracesRegex = /{{[^)]*}}/g;

const toTitleCase = (text) => {
    try {
        if (text) {
            if (typeof text !== "string") {
                throw new Error("'toTitleCase' value must be of type string.");
            }

            const str = text.replace(/[-_]/g, " ").trim();
            let upper = true;
            let newStr = "";
            for (let i = 0, l = str?.length; i < l; i++) {
                if (str[i] === " ") {
                    upper = true;
                    newStr += " ";
                    continue;
                }
                newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase();
                upper = false;
            }
            return newStr;
        }
        return text;
    } catch (e) {
        console.error(e);
        toast.error(
            e.message ? `Error: ${e.message}` : "Please try again later."
        );
        return "";
    }
};

const toTitleCaseFromCamelCase = (text) => {
    try {
        if (text) {
            if (typeof text !== "string") {
                throw new Error(
                    "'toTitleCaseFromCamelCase' value must be of type string."
                );
            }

            const result = text.replace(/([A-Z])/g, " $1");
            return result.charAt(0).toUpperCase() + result.slice(1);
        }
        return text;
    } catch (e) {
        console.error(e);
        toast.error(
            e.message ? `Error: ${e.message}` : "Please try again later."
        );
        return "";
    }
};

const toReplaceSpaceWithHyphen = (text) => {
    try {
        if (text) {
            if (typeof text !== "string") {
                throw new Error(
                    "'toReplaceSpaceWithHyphen' value must be of type string."
                );
            }
            return text.replace(/ +/g, "-").toLowerCase();
        }
        return text;
    } catch (e) {
        console.error(e);
        toast.error(
            e.message ? `Error: ${e.message}` : "Please try again later."
        );
        return "";
    }
};

const convertToSnakeCase = (text) => {
    try {
        if (text) {
            if (typeof text !== "string") {
                throw new Error(
                    "'convertToSnakeCase' value must be of type string."
                );
            }
            return text
                .split(/(?=[A-Z])/)
                .join("_")
                .toLowerCase();
        }
        return text;
    } catch (e) {
        console.error(e);
        toast.error(
            e.message ? `Error: ${e.message}` : "Please try again later."
        );
        return "";
    }
};

const toTitleCaseFromSnakeAndCamel = (text) => {
    const snakeCase = convertToSnakeCase(text);
    return toTitleCase(snakeCase);
};

const capitalizeOnlyFirstLetterOfString = (text) =>
    `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;

const convertStringToCamelCase = (string) =>
    string
        ?.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
            index === 0 ? letter.toLowerCase() : letter.toUpperCase()
        )
        .replace(/\s+/g, "");

const isGSMAlphabet = (text) => gsm7Encode.test(text);

const truncateLongString = (text, maxLength = 10) => {
    try {
        if (text) {
            if (typeof text !== "string") {
                throw new Error(
                    "'truncateLongString' value must be of type string."
                );
            }
            return text.length > maxLength
                ? text.slice(0, maxLength - 1) + "..."
                : text;
        }
        return text;
    } catch (e) {
        console.error(e);
        toast.error(
            e.message ? `Error: ${e.message}` : "Please try again later."
        );
        return "";
    }
};

export {
    curlyBracesRegex,
    toTitleCase,
    toTitleCaseFromCamelCase,
    toReplaceSpaceWithHyphen,
    convertToSnakeCase,
    toTitleCaseFromSnakeAndCamel,
    capitalizeOnlyFirstLetterOfString,
    convertStringToCamelCase,
    isGSMAlphabet,
    truncateLongString,
};
