import React, { useCallback, useContext, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import Medal from "../assets/images/medal.svg";
import Group from "../assets/images/group.svg";
import { DataContext } from "./dataContext";
import { UserContext } from "./userContext";
import { AccessPermissionModuleNames, AccessPermissionModules } from "Data";
import {
    exportFilterMembers,
    filterMembers,
    getMembers,
    getSegmentCategories,
} from "Services";
import { buildMemberFilterConfig, getMongoDBQuery } from "Utils";

const MembersContext = React.createContext();

const resetMemberStatus = {
    memberList: [],
    limit: 25,
    skip: 1,
    isLoading: true,
    totalCount: 0,
    countMembers: false,
};

const initialState = {
    segments: [],
    selectedSegmentId: null,
    sortDirection: "",
    sortBy: "",
    isLoadingSegmentCategories: true,
    segmentCategories: {},
    searchText: "",
    currentFilters: {},
    filterConfig: {},
    otherCategoryId: "",
    selectedLoyaltyCardNumber: "",
    isExporting: false,
    ...resetMemberStatus,
};

const MembersContextActions = {
    SET_MEMBERS: "setMembers",
    SET_IS_LOADING: "setIsLoading",
    SET_IS_EXPORTING: "setIsExporting",
    UPDATE_MEMBER: "updateMember",
    SET_SEGMENTS_CATEGORIES: "setSegmentCategories",
    SET_SEGMENTS: "setSegments",
    SET_IS_LOADING_SEGMENTS: "setIsLoadingSegments",
    SEARCHING: "memberSearching",
    CHANGE_SEARCH_TEXT: "changeSearchText",
    SET_PAGINATION_INFO: "setPaginationInfo",
    MEMBER_DATA_RESET: "memberDataReset",
    SET_SORTING_ORDER: "setSortingOrder",
    SET_CURRENT_FILTERS: "setCurrentFilters",
    SET_FILTER_CONFIG: "setFilterConfig",
    SET_SELECTED_SEGMENT: "setSelectedSegment",
    UPDATE_SEGMENTS: "updateSegments",
    SET_CARD_NUMBER: "setCardNumber",
    SET_TOTAL_MEMBERS: "setTotalMembers",
};

const reducer = (state, action) => {
    switch (action.type) {
        case MembersContextActions.SET_MEMBERS: {
            const newState = {
                ...state,
                isLoading: false,
                memberList: action.members.items,
            };
            if (action.shouldReset) {
                newState.skip = 1;
            }
            return newState;
        }
        case MembersContextActions.SET_TOTAL_MEMBERS: {
            const newState = {
                ...state,
                totalCount: action.totalCount,
                countMembers: false,
            };
            return newState;
        }
        case MembersContextActions.SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.status,
                countMembers: !!action.countMembers,
            };
        }
        case MembersContextActions.SET_IS_LOADING_SEGMENTS: {
            return {
                ...state,
                isLoadingSegmentCategories: action.status,
            };
        }
        case MembersContextActions.SET_SEGMENTS_CATEGORIES: {
            return {
                ...state,
                isLoadingSegmentCategories: false,
                segmentCategories: action.categories || {},
                otherCategoryId: action.otherCategoryId || "",
            };
        }
        case MembersContextActions.SET_SEGMENTS: {
            return {
                ...state,
                segments: action.segments,
            };
        }
        case MembersContextActions.UPDATE_MEMBER: {
            return {
                ...state,
                memberList: state.memberList.map((item) => {
                    if (item._id === action.member._id) {
                        return action.member;
                    }
                    return item;
                }),
            };
        }
        case MembersContextActions.CHANGE_SEARCH_TEXT: {
            return {
                ...state,
                searchText: action.searchText,
                ...(state.searchText !== action.searchText
                    ? { ...resetMemberStatus }
                    : {}),
            };
        }
        case MembersContextActions.SET_PAGINATION_INFO: {
            if (action.limit) {
                return { ...state, limit: action.limit };
            } else {
                return { ...state, skip: action.skip };
            }
        }
        case MembersContextActions.MEMBER_DATA_RESET: {
            return {
                ...state,
                memberList: [],
                limit: action.limit,
                skip: action.skip,
                isLoading: true,
            };
        }
        case MembersContextActions.SET_SORTING_ORDER: {
            return {
                ...state,
                sortDirection: action.sortDirection,
                sortBy: action.sortBy,
                memberList: [],
                skip: 1,
                isLoading: true,
            };
        }
        case MembersContextActions.SET_CURRENT_FILTERS: {
            return {
                ...state,
                currentFilters: action.filters || {},
                searchText: "",
                ...resetMemberStatus,
            };
        }
        case MembersContextActions.SET_FILTER_CONFIG: {
            return {
                ...state,
                filterConfig: action.filterConfig,
            };
        }
        case MembersContextActions.SET_SELECTED_SEGMENT: {
            return {
                ...state,
                selectedSegmentId: action.selectedSegmentId,
                currentFilters: action.filters,
                searchText: "",
                ...resetMemberStatus,
            };
        }
        case MembersContextActions.SET_CARD_NUMBER: {
            return {
                ...state,
                selectedLoyaltyCardNumber: action.selectedLoyaltyCardNumber,
            };
        }
        case MembersContextActions.SET_IS_EXPORTING: {
            return {
                ...state,
                isExporting: action.status,
            };
        }
        default:
            return state;
    }
};

let searchTimeout;

const MembersContextProvider = (props) => {
    const {
        isAuth,
        regionId,
        config,
        userConfigLoaded,
        selectedRegion,
        isAuthorizedForAction,
    } = useContext(UserContext);
    const {
        contactAttributes,
        tags,
        tiers,
        affinityGroups,
        segments: segmentsData,
        loadSegments,
    } = useContext(DataContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    const loadMembers = useCallback(
        async ({ shouldReset = false, limit, skip, countMembers = false }) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.MEMBER,
                    AccessPermissionModules[AccessPermissionModuleNames.MEMBER]
                        .actions.ListMembers
                )
            ) {
                try {
                    dispatch({
                        type: MembersContextActions.SET_IS_LOADING,
                        status: true,
                        countMembers,
                    });

                    const newLimit = limit || state.limit;
                    const newSkip = ((skip || state.skip) - 1) * state.limit;
                    let contactResponse;

                    //* Check filters are not empty
                    if (
                        state.currentFilters &&
                        Object.keys(state.currentFilters)?.length > 0
                    ) {
                        const filters = getMongoDBQuery(
                            state.currentFilters,
                            state.filterConfig
                        );

                        contactResponse = await filterMembers(
                            {
                                limit: newLimit,
                                skip: newSkip,
                                ...(state.sortDirection
                                    ? {
                                        sortDirection:
                                            state.sortDirection?.toUpperCase(),
                                    }
                                    : {}),
                                ...(state.sortBy
                                    ? { sortBy: state.sortBy }
                                    : {}),
                                searchKey: state.searchText,
                                regionId: regionId,
                                projection: config.memberTableColumns,
                                countMembers,
                            },
                            filters
                        );
                    } else {
                        contactResponse = await getMembers({
                            limit: newLimit,
                            skip: newSkip,
                            regionId: regionId,
                            sortDirection: state.sortDirection?.toUpperCase(),
                            sortBy: state.sortBy,
                            searchKey: state.searchText,
                            projection: config.memberTableColumns,
                            countMembers,
                        });
                    }

                    if (countMembers) {
                        dispatch({
                            type: MembersContextActions.SET_TOTAL_MEMBERS,
                            totalCount: contactResponse.data.total,
                        });
                    }

                    dispatch({
                        type: MembersContextActions.SET_MEMBERS,
                        members: contactResponse.data,
                        shouldReset,
                    });
                } catch (e) {
                    dispatch({
                        type: MembersContextActions.SET_IS_LOADING,
                        status: false,
                    });
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load members!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                        </div>
                    );
                }
            } else {
                dispatch({
                    type: MembersContextActions.SET_IS_LOADING,
                    status: false,
                });
            }
        },
        [
            dispatch,
            state.limit,
            state.skip,
            state.sortDirection,
            state.sortBy,
            state.searchText,
            state.currentFilters,
            regionId,
            state.filterConfig,
            config.memberTableColumns,
            isAuthorizedForAction,
        ]
    );

    const resetMember = useCallback(
        ({ skip, pageReset, limit }) => {
            if (pageReset) {
                dispatch({
                    type: MembersContextActions.MEMBER_DATA_RESET,
                    skip: skip,
                    limit: limit,
                });
            }
        },
        [dispatch]
    );

    const onChangePagination = useCallback(
        async ({ skip = state.skip, limit = state.limit }) => {
            if (state.limit !== limit) {
                dispatch({
                    type: MembersContextActions.SET_PAGINATION_INFO,
                    limit,
                });
                await loadMembers({
                    shouldReset: true,
                    limit,
                    skip: 1,
                    countMembers: false,
                });
            } else {
                // * If skip change, need to load the data manually without resetting
                dispatch({
                    type: MembersContextActions.SET_PAGINATION_INFO,
                    skip,
                });
                await loadMembers({ skip });
            }
        },
        [dispatch, state.skip, state.limit, loadMembers]
    );

    const setSortingOrder = useCallback(
        async ({ sortBy, sortDirection }) => {
            try {
                if (
                    !(sortDirection === state.sortDirection) ||
                    !(sortBy === state.sortBy)
                ) {
                    dispatch({
                        type: MembersContextActions.SET_SORTING_ORDER,
                        sortDirection: sortDirection,
                        sortBy: sortBy,
                    });
                }
            } catch (e) {
                console.error(e);
                dispatch({
                    type: MembersContextActions.SET_IS_LOADING,
                    status: false,
                });
            }
        },
        [dispatch, state.sortDirection, state.sortBy]
    );

    const loadSegmentsCategories = useCallback(
        async (silent = false) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.SEGMENT,
                    AccessPermissionModules[AccessPermissionModuleNames.SEGMENT]
                        .actions.ListSegmentCaregories
                )
            ) {
                try {
                    if (!silent) {
                        dispatch({
                            type: MembersContextActions.SET_IS_LOADING_SEGMENTS,
                            status: true,
                        });
                    }
                    const segmentCategoriesResponse =
                        await getSegmentCategories(regionId);

                    const transformedSegmentCategories =
                        segmentCategoriesResponse.items.reduce(
                            (result, { _id, name, createdOn, iconUrl }) => {
                                result[_id] = {
                                    _id,
                                    name,
                                    createdOn,
                                    segmentIds: new Set(),
                                    iconUrl,
                                };

                                return result;
                            },

                            {}
                        );
                    let otherCategoryId = "";
                    Object.keys(transformedSegmentCategories).forEach((key) => {
                        if (
                            transformedSegmentCategories[key].name === "Other"
                        ) {
                            otherCategoryId =
                                transformedSegmentCategories[key]._id;
                        }
                    });
                    dispatch({
                        type: MembersContextActions.SET_SEGMENTS_CATEGORIES,
                        otherCategoryId: otherCategoryId,
                        categories: {
                            ...transformedSegmentCategories,
                            tier: {
                                createdOn: new Date(),
                                iconUrl: Medal,
                                name: "Tier",
                                segmentIds: new Set(),
                                _id: "tier.tierId",
                            },
                            affinityGroup: {
                                createdOn: new Date(),
                                iconUrl: Group,
                                name: "Affinity Group",
                                segmentIds: new Set(),
                                _id: "affinityGroup.affinityGroupId",
                            },
                        },
                    });
                } catch (e) {
                    console.error(e);
                    if (!silent) {
                        dispatch({
                            type: MembersContextActions.SET_IS_LOADING_SEGMENTS,
                            status: false,
                        });
                    }
                }
            }
        },
        [regionId, isAuthorizedForAction, dispatch]
    );

    const updateSegments = useCallback(() => {
        loadSegmentsCategories(true);
        loadSegments();
    }, [loadSegments, loadSegmentsCategories]);

    const updateMember = useCallback(
        (member) => {
            dispatch({ type: MembersContextActions.UPDATE_MEMBER, member });
        },
        [dispatch]
    );

    const onChangeSearchText = useCallback(
        (searchText) => {
            dispatch({
                type: MembersContextActions.CHANGE_SEARCH_TEXT,
                searchText,
            });
        },
        [dispatch]
    );

    const setCurrentFilters = useCallback(
        (filters) => {
            dispatch({
                type: MembersContextActions.SET_CURRENT_FILTERS,
                filters,
            });
        },
        [dispatch]
    );

    const setFilterConfig = useCallback(
        (filterConfig) => {
            dispatch({
                type: MembersContextActions.SET_FILTER_CONFIG,
                filterConfig,
            });
        },
        [dispatch]
    );

    const selectSegment = useCallback(
        (segment, segmentId) => {
            dispatch({
                type: MembersContextActions.SET_SELECTED_SEGMENT,
                selectedSegmentId: segmentId,
                filters: segment?.filter || {},
            });
        },
        [dispatch]
    );

    const setSelectedLoyaltyCardNo = useCallback(
        (selectedLoyaltyCardNumber) => {
            dispatch({
                type: MembersContextActions.SET_CARD_NUMBER,
                selectedLoyaltyCardNumber,
            });
        },
        [dispatch]
    );

    const onExportMembers = useCallback(
        async (notificationEmails) => {
            try {
                dispatch({
                    type: MembersContextActions.SET_IS_EXPORTING,
                    status: true,
                });
                await exportFilterMembers(
                    {
                        sortDirection: state.sortDirection?.toUpperCase(),
                        sortBy: state.sortBy,
                        regionId: selectedRegion._id,
                        projection: config.memberTableColumns,
                        notificationEmails: notificationEmails,
                    },
                    {
                        ...(state.currentFilters &&
                        Object.keys(state.currentFilters)?.length > 0
                            ? getMongoDBQuery(
                                state.currentFilters,
                                state.filterConfig
                            )
                            : {}),
                    }
                );
                toast.success(
                    "You will receive the exported file to the provided email"
                );
            } catch (e) {
                console.error(e);
                toast.error(
                    <div>
                        Failed to export member list!
                        <br />
                        {e.message
                            ? `Error: ${e.message}`
                            : "Please try again later."}
                    </div>
                );
            } finally {
                dispatch({
                    type: MembersContextActions.SET_IS_EXPORTING,
                    status: false,
                });
            }
        },
        [selectedRegion, dispatch, config.memberTableColumns, state]
    );

    useEffect(() => {
        if (isAuth && userConfigLoaded) {
            loadMembers({ shouldReset: true, skip: 1, countMembers: true });
        }
        return () => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // * Add only the dependencies which need to reset the current data
        isAuth,
        // state.limit,
        state.sortBy,
        state.sortDirection,
        state.currentFilters,
        state.searchText,
        userConfigLoaded,
        config.memberTableColumns,
    ]);

    useEffect(() => {
        if (isAuth) {
            loadSegmentsCategories();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth, tiers, affinityGroups]);

    useEffect(() => {
        if (isAuth) {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.SEGMENT,
                    AccessPermissionModules[AccessPermissionModuleNames.SEGMENT]
                        .actions.ListSegments
                )
            ) {
                try {
                    if (state.otherCategoryId) {
                        const withoutOtherCategory = segmentsData.filter(
                            (item) => item.categoryId !== state.otherCategoryId
                        );
                        const otherCategory = segmentsData
                            .filter(
                                (item) =>
                                    item.categoryId === state.otherCategoryId
                            )
                            .map((item) => {
                                const filter = getMongoDBQuery(
                                    item.filter,
                                    state.filterConfig
                                );
                                if (
                                    filter &&
                                    filter.hasOwnProperty(
                                        "affinityGroup.affinityGroupId"
                                    )
                                ) {
                                    item.categoryId = "affinityGroup";
                                }
                                if (
                                    filter &&
                                    filter.hasOwnProperty("tier.tierId")
                                ) {
                                    item.categoryId = "tier";
                                }
                                return item;
                            });
                        dispatch({
                            type: MembersContextActions.SET_SEGMENTS,
                            segments: [
                                ...otherCategory,
                                ...withoutOtherCategory,
                            ],
                        });
                    }
                } catch (e) {
                    dispatch({
                        type: MembersContextActions.SET_IS_LOADING_SEGMENTS,
                        status: false,
                    });
                }
            }
        }
    }, [
        isAuth,
        isAuthorizedForAction,
        segmentsData,
        state.filterConfig,
        state.otherCategoryId,
    ]);

    // * Auto update filterConfig
    useEffect(() => {
        if (Object.keys(contactAttributes).length > 0) {
            const config = buildMemberFilterConfig(
                {
                    ...contactAttributes,
                    "tier.tierId": {
                        label: "Tier",
                        type: "select",
                        fieldSettings: tiers.map((tier) => ({
                            title: tier.name,
                            value: tier._id,
                        })),
                    },
                    "affinityGroup.affinityGroupId": {
                        label: "Affinity Group",
                        type: "select",
                        fieldSettings: affinityGroups.map((affinityGroup) => ({
                            title: affinityGroup.name,
                            value: affinityGroup._id,
                        })),
                    },
                },
                tags || []
            );

            setFilterConfig(config);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactAttributes, tags, affinityGroups, tiers]);

    const value = {
        ...state,
        resetMember,
        setSortingOrder,
        onChangePagination,
        loadMembers,
        updateMember,
        onChangeSearchText,
        loadSegmentsCategories,
        setCurrentFilters,
        setFilterConfig,
        selectSegment,
        updateSegments,
        setSelectedLoyaltyCardNo,
        onExportMembers,
    };

    return (
        <MembersContext.Provider value={value}>
            {props.children}
        </MembersContext.Provider>
    );
};

const MemberContextConsumer = MembersContext.Consumer;

export { MembersContext, MembersContextProvider, MemberContextConsumer };
