const NotificationFilterOptions = {
    CHANNEL: "CHANNEL",
    LOYALTY_ACTIVITY: "LOYALTY_ACTIVITY",
    TO_SMS: "TO_SMS",
    TO_EMAIL: "TO_EMAIL",
    STATUS: "STATUS",
};

const NotificationChannel = {
    SMS: "SMS",
    EMAIL: "EMAIL",
    PUSH: "PUSH",
    WHATSAPP: "WHATSAPP",
};

const NotificationStatus = {
    SENT: "SENT",
    OPEN: "OPEN",
    DELIVERED: "DELIVERED",
    QUEUED: "QUEUED",
    FAILED: "FAILED",
    INTERNAL_ERROR: "INTERNAL_ERROR",
    ACCEPTED: "ACCEPTED",
    CLICK: "CLICK",
    SENDING: "SENDING",
    BOUNCED: "BOUNCED",
    RECEIVING: "RECEIVING",
    RECEIVED: "RECEIVED",
    SCHEDULED: "SCHEDULED",
    UNDELIVERED: "UNDELIVERED",
    DELIVERY_UNKNOWN: "DELIVERY_UNKNOWN",
    READ: "READ",
    BLOCKED: "BLOCKED",
    SPAM_REPORT: "SPAM_REPORT",
};

export { NotificationFilterOptions, NotificationStatus, NotificationChannel };
