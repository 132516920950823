import {
    fetchGet,
    fetchPost,
    fetchPut,
    jsonToQueryParam,
    fetchDelete, fetchPostMultipart,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const getAffinityGroups = ({ limit, skip, searchKey, regionId }) => {
    return fetchGet(
        `${BASE_URL}affinitygroups?${jsonToQueryParam({
            limit,
            skip,
            searchKey,
            regionId,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.AFFINITY_GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.AFFINITY_GROUPS]
                .actions.ListAffinityGroups
        )
    );
};

const getAffinityGroupById = ({ affinityGroupId }) => {
    return fetchGet(
        `${BASE_URL}affinitygroups/${affinityGroupId}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.AFFINITY_GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.AFFINITY_GROUPS]
                .actions.ListAffinityGroups
        )
    );
};

const editAffinityGroups = ({ _id, name, benefits, description }) => {
    return fetchPut(
        `${BASE_URL}affinitygroups/${_id}`,
        {
            name,
            benefits,
            description,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.AFFINITY_GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.AFFINITY_GROUPS]
                .actions.UpdateAffinityGroup
        )
    );
};

const deleteAffinityGroups = (id) => {
    return fetchDelete(
        `${BASE_URL}affinitygroups/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.AFFINITY_GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.AFFINITY_GROUPS]
                .actions.DeleteAffinityGroup
        )
    );
};

const createAffinityAffinityGroup = (newAffinityGroupPayload) => {
    return fetchPost(
        `${BASE_URL}affinitygroups`,
        newAffinityGroupPayload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.AFFINITY_GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.AFFINITY_GROUPS]
                .actions.CreateAffinityGroup
        )
    );
};

const getAllAffinityGroups = (args) => {
    return new Promise(async (resolve, reject) => {
        const limit = 500;
        let totalCount = 0,
            affinityGroups = [];
        try {
            do {
                const affinityGroupsResponse = await getAffinityGroups({
                    limit,
                    skip: affinityGroups.length,
                    ...args,
                });
                totalCount = affinityGroupsResponse.total;
                affinityGroups = [
                    ...affinityGroups,
                    ...affinityGroupsResponse.items,
                ];
            } while (affinityGroups.length < totalCount);
            resolve(affinityGroups);
        } catch (e) {
            reject(e);
        }
    });
};


const getImportedJobLis= (payload) => {
    return fetchGet(
        `${BASE_URL}affinitygroups/importjobs?${jsonToQueryParam(payload)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.AFFINITY_GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.AFFINITY_GROUPS]
                .actions.ListAffinityGroups
        )
    );
};

const importAffinityGroupsJobs = (payload) => {
    return fetchPost(BASE_URL + "affinitygroups/importjobs", payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TRANSACTION,
            AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION]
                .actions.CreateTransactionImportJob
        )
    );
}

const getImportedJobLogs= (payload) => {
    return fetchGet(
        `${BASE_URL}affinitygroups/importjobs/logs?${jsonToQueryParam(payload)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.AFFINITY_GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.AFFINITY_GROUPS]
                .actions.ListAffinityGroups
        )
    );
};

const exportAffinityGroupImportedJobLog = (payload) => {
    return fetchGet(
        `${BASE_URL}affinitygroups/importjobs/logs/export?${jsonToQueryParam(payload)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MEMBER_NOTE,
            AccessPermissionModules[AccessPermissionModuleNames.MEMBER_NOTE].actions
                .ListMemberNotes
        )
    );
}

const affinityGroupsFileUpload = (queryObj, fileFormData)=> {
    return fetchPostMultipart(`${BASE_URL}affinitygroups/importjobs/uploadfile?${jsonToQueryParam(queryObj)}`,fileFormData);
}
export {
    getAffinityGroups,
    editAffinityGroups,
    deleteAffinityGroups,
    createAffinityAffinityGroup,
    getAllAffinityGroups,
    getImportedJobLis,
    affinityGroupsFileUpload,
    importAffinityGroupsJobs,
    getImportedJobLogs,
    exportAffinityGroupImportedJobLog,
    getAffinityGroupById
};
