import {
    fetchGetWithTotal,
    fetchPost,
    fetchDelete,
    jsonToQueryParam,
    fetchGet,
    fetchPut,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import {
    AccessPermissionModules,
    UserStatus,
    UserTypes,
    AccessPermissionModuleNames,
} from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}identityservice/`;

const getIdentityUsers = ({
    limit = 100,
    skip,
    type = "USER",
    regionId,
    merchantId,
    searchKey,
}) => {
    return fetchGetWithTotal(
        `${BASE_URL}users?${jsonToQueryParam({
            limit,
            skip: skip || 0,
            type,
            regionId,
            merchantId,
            searchKey,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .ListUsers
        )
    );
};

const postUserDataset = ({ projection, datasetKey }) => {
    return fetchPost(
        `${BASE_URL}userdatasets`,
        {
            datasetKey: datasetKey,
            datasetValue: JSON.stringify(projection),
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_DATASET,
            AccessPermissionModules[AccessPermissionModuleNames.USER_DATASET]
                .actions.CreateDataset
        )
    );
};

const getUserDataset = ({ datasetKey }) => {
    return fetchGetWithTotal(
        `${BASE_URL}userdatasets?${jsonToQueryParam({
            datasetKey: datasetKey,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_DATASET,
            AccessPermissionModules[AccessPermissionModuleNames.USER_DATASET]
                .actions.ListDatasets
        )
    );
};

const getGroups = ({ limit = 100, skip, regionId, merchantId, searchKey }) => {
    return fetchGet(
        `${BASE_URL}groups?${jsonToQueryParam({
            limit,
            skip: skip || 0,
            regionId,
            merchantId,
            searchKey,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.GROUPS].actions
                .ListGroups
        )
    );
};
const getAllGroups = (args) => {
    return new Promise(async (resolve, reject) => {
        const limit = 500;
        let totalCount = 0,
            groups = [];
        try {
            do {
                const response = await getGroups({
                    limit,
                    skip: groups.length,
                    ...args,
                });
                totalCount = response.total;
                groups = [...groups, ...response.items];
            } while (groups.length < totalCount);
            resolve(groups);
        } catch (e) {
            reject(e);
        }
    });
};

const getGroup = (id) => {
    return fetchGet(
        `${BASE_URL}groups/${id}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.GROUPS].actions
                .ListGroups
        )
    );
};

const getAuditLogs= (payload) => {
    return fetchGet(
        `${BASE_URL}auditlogs?${jsonToQueryParam(payload)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.AUDIT_LOG,
            AccessPermissionModules[AccessPermissionModuleNames.AUDIT_LOG].actions
                .ListAuditLogs
        )
    );
};


const createGroup = ({
    regionId,
    merchantId,
    type,
    name,
    description,
    policies,
}) => {
    return fetchPost(
        `${BASE_URL}groups`,
        {
            regionId,
            merchantId,
            type,
            name,
            description,
            policies,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.GROUPS].actions
                .CreateGroup
        )
    );
};
const deleteGroup = (id) => {
    return fetchDelete(
        `${BASE_URL}groups/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.GROUPS].actions
                .DeleteGroup
        )
    );
};

const updateGroup = (groupId, groupData) => {
    return fetchPut(
        `${BASE_URL}groups/${groupId}`,
        groupData,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.GROUPS].actions
                .UpdateGroup
        )
    );
};

const getIdentityUser = (userId) => {
    return fetchGet(
        `${BASE_URL}users/${userId}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .GetUser
        )
    );
};
const updateIdentityUser = (userId, { userData }) => {
    return fetchPut(
        `${BASE_URL}users/${userId}`,
        { userData },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .UpdateUser
        )
    );
};

const resetUserPassword = (resetPayload) => {
    return fetchPost(
        `${BASE_URL}users/resetpassword`,
        resetPayload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .ResetPassword
        )
    );
};

const getUserActivities = ({ userId, limit, skip = 0 }) => {
    //   const data={
    //     "ipAddress": "string",
    //     "sessionId": "string",
    //     "event": "Login",
    //     "createdOn": "2021-10-03T15:14:26.162Z"
    //   };
    //   const dataset=[];
    //   for(let i=skip;i<limit+skip;i++){
    // dataset.push({...data,_id:i,index:i})
    //   }
    //   return new Promise(r=>
    //     setTimeout(()=>
    //     r(
    //       {
    //         "limit": 0,
    //         "skip": 0,
    //         "total": 200,
    //         "items": dataset
    //       }
    //     ),1500)
    //   )
    return fetchGet(
        `${BASE_URL}useractivities?${jsonToQueryParam({ userId })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_ACTIVITY,
            AccessPermissionModules[AccessPermissionModuleNames.USER_ACTIVITY]
                .actions.ListUserActivities
        )
    );
};

// const deleteMemberNotes = (id) => {
//     return fetchDelete(`${BASE_URL}membernotes/${id}`);
//   };

const addUserPermissions = ({ userId, regionId, permissions }) => {
    return fetchPost(
        `${BASE_URL}userpermissions`,
        {
            userId,
            regionId,
            permissions,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_PERMISSION,
            AccessPermissionModules[AccessPermissionModuleNames.USER_PERMISSION]
                .actions.CreateUserPermission
        )
    );
};

const updateUserPermission = ({ id, permissions }) => {
    return fetchPut(
        `${BASE_URL}userpermissions/${id}`,
        {
            permissions: permissions.permissions,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_PERMISSION,
            AccessPermissionModules[AccessPermissionModuleNames.USER_PERMISSION]
                .actions.UpdateUserPermission
        )
    );
};
const deleteUserAccount = (id) => {
    return fetchDelete(
        `${BASE_URL}users/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .DeleteUser
        )
    );
};

const createUser = ({
    boundary,
    regionId,
    merchantId,
    type = UserTypes.USER,
    status = UserStatus.ACTIVE,
    userData,
}) => {
    return fetchPost(
        `${BASE_URL}users`,
        {
            boundary,
            regionId,
            merchantId,
            type,
            status,
            userData,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .CreateUser
        )
    );
};

const getUserPermissions = ({ userId }) => {
    return fetchGet(
        `${BASE_URL}userpermissions?${jsonToQueryParam({ userId })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_PERMISSION,
            AccessPermissionModules[AccessPermissionModuleNames.USER_PERMISSION]
                .actions.ListUserPermissions
        )
    );
};

const getModules = () => {
    return fetchGet(
        `${BASE_URL}modules`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MODULES,
            AccessPermissionModules[AccessPermissionModuleNames.MODULES].actions
                .ListModules
        )
    );
};

const getIntegrations = ({ limit, skip = 0, regionId, merchantId }) => {
    return fetchGet(
        `${BASE_URL}users?${jsonToQueryParam({
            type: UserTypes.CLIENT,
            limit,
            skip,
            regionId,
            merchantId,
        })}`
    );
};

const getIntegration = (id) => {
    return getIdentityUser(id);
};

const deleteIntegration = (id) => {
    return deleteUserAccount(id);
};
const createIntegration = ({
    boundary,
    regionId,
    merchantId,
    type = UserTypes.CLIENT,
    status = UserStatus.ACTIVE,
    clientData,
}) => {
    return fetchPost(`${BASE_URL}users`, {
        boundary,
        regionId,
        merchantId,
        type,
        status,
        clientData,
    });
};

const getProfile = () => {
    return fetchGet(`${BASE_URL}accounts/profile`);
};
export {
    getIdentityUsers,
    postUserDataset,
    getUserDataset,
    getGroups,
    deleteGroup,
    getGroup,
    getIdentityUser,
    updateIdentityUser,
    getUserActivities,
    addUserPermissions,
    deleteUserAccount,
    resetUserPassword,
    createUser,
    getUserPermissions,
    updateUserPermission,
    getModules,
    createGroup,
    updateGroup,
    getIntegrations,
    createIntegration,
    deleteIntegration,
    getIntegration,
    getProfile,
    getAllGroups,
    getAuditLogs
};
