// import UserProfilePage from 'Components/controlPanel/users/userProfile/UserProfilePage';
import React, {lazy, Suspense, useContext, useEffect, useState} from "react";
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";
//import MainNavigation from '../navigation/MainNavigation';
import Navigation from "../navigation/Navigation";
import { UserContext } from "Contexts";
import { isEmptyObject } from "Utils";
import qs from "qs";
import SidebarMenu from "../navigation/sidebarMenu/SidebarMenu";
import "./Home.css";
import "../common/detailsAsLabelValue/DetailsAsLabelValue.scss";

// const DashboardPage = lazy(() => import('../dashboard/DashboardPage'));
const MembersPage = lazy(() => import("../members/MembersPage"));
const TiersPage = lazy(() => import("../tiers/TiersPage"));
const AffinityGroupPage = lazy(() => import("../tiers/AffinityGroupPage"));
const TransactionsPage = lazy(() => import("../transactions/TransactionsPage"));
// const Configurations = lazy(() => import('../configurations/Configurations'));
// const ProfilePage = lazy(() => import('../members/profile/ProfilePage'));
const MyProfilePage = lazy(() => import("../myProfile/MyProfilePage"));

const CreateTierPage = lazy(() =>
  import("../tiers/createTiers/CreateTierPage")
);
const NotificationPage = lazy(() => import("../notification/NotificationPage"));
const CardsPage = lazy(() => import("../cards/CardsPage"));
const ControlPanelPage = lazy(() => import("../controlPanel/ControlPanelPage"));
const CharityPage = lazy(() => import("../controlPanel/CharityPage"));
// const UserProfilePage = lazy(() => import('../controlPanel/users/userProfile/UserProfilePage'));
const PointRulesPage = lazy(() => import("../pointRules/PointRulesPage"));
const FindMembersPage = lazy(() => import("../findMembers/FindMembersPage"));
const MerchantsPage = lazy(() => import("../merchants/MerchantsPage"));
const RedemptionsPage = lazy(() => import("../redemptions/RedemptionsPage"));
const AnalyticsPage = lazy(() => import("../analyticsView/AnalyticsPage"));
const FraudulencePage = lazy(() => import("../fraud/FraudulencePage"));
const JobsPage = lazy(() => import("../jobs/JobsPage"));
const CampaignPage = lazy(() => import("../campaigns/CampaignPage"));

const Home = () => {
  const { selectedRegion } = useContext(UserContext);
  const history = useHistory();
  const [activeMainTab, setActiveMainTab] = useState("Members");
  const location = useLocation();

  useEffect(() => {
    if (!isEmptyObject(selectedRegion)) {
      setTimeout(() => {
        history.replace({
          pathname: "/refresh",
          search: "?url=" + encodeURIComponent(location.pathname),
        });
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);
  useEffect(() => {
    const pathname= location.pathname.split("/");
    if (pathname[1]==="profile") {
      setActiveMainTab("Profile")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* <MainNavigation/> */}
      <div className="home-wrapper d-flex flex-row">
        <div className={activeMainTab!=="Profile"?"sidenav":"without-mid-sidenav"}>
          <SidebarMenu activeMainTab={activeMainTab} setActiveMainTab={setActiveMainTab}/>
          {activeMainTab!=="Profile"&& <Navigation activeMainTab={activeMainTab} setActiveMainTab={setActiveMainTab}/>}
        </div>
        <div className={activeMainTab!=="Profile"?"main":"without-mid-sidenav-main"}>
          <div className="flex-fill">
            {/* <div className="home-content flex-fill d-flex flex-column w-100"> */}
            <div className={`home-content ${location.pathname.startsWith("/find-members") && "home-content-bg-grey"} flex-fill d-flex flex-column`}>
              <div className="scrollbar">
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    <Route
                        path="/refresh"
                        render={() => {
                          const url =
                              qs.parse(location.search, { ignoreQueryPrefix: true })
                                  ?.url || "/";
                          return <Redirect to={url} />;
                        }}
                    />
                    {/* <Route name="Dashboard" exact path="/" component={DashboardPage} /> */}
                    <Route name="Members" exact path="/" component={MembersPage} />
                    <Route name="Members" path="/members" component={MembersPage} />
                    <Route
                        name="FindMembers"
                        path="/find-members"
                        component={FindMembersPage}
                    />
                    <Route
                        name="PointRules"
                        exact
                        path="/point-rules"
                        component={PointRulesPage}
                    />
                    <Route
                        name="Redemptions"
                        path="/redemptions"
                        component={RedemptionsPage}
                    />

                    <Route
                        name="CreateTiers"
                        exact
                        path="/tiers/create-tier"
                        component={CreateTierPage}
                    />
                    <Route
                        name="Transactions"
                        path="/transactions"
                        component={TransactionsPage}
                    />
                    <Route name="Tiers" path="/tiers" component={TiersPage} />
                    <Route name="AffinityGroup" path="/affinity-groups" component={AffinityGroupPage} />
                    <Route
                        name="Merchants"
                        path="/merchants"
                        component={MerchantsPage}
                    />
                    <Route
                        name="Notifications"
                        path="/notifications"
                        component={NotificationPage}
                    />
                    <Route
                      name="Campaigns"
                      path="/campaigns"
                      component={CampaignPage}
                  />
                    <Route
                        name="Analytics"
                        path="/analytics"
                        component={AnalyticsPage}
                    />
                    <Route
                        name="Fraudulence"
                        path="/fraudulence"
                        component={FraudulencePage}
                    />
                    <Route
                        name="Jobs"
                        path="/jobs"
                        component={JobsPage}
                    />
                    <Route name="Cards" path="/cards" component={CardsPage} />
                    <Route
                        name="ControlPanel"
                        path="/control-panel"
                        component={ControlPanelPage}
                    />
                    <Route
                        name="CharityPage"
                        path="/charity-list"
                        component={CharityPage}
                    />
                    <Route
                        name="Profile"
                        exact
                        path="/profile"
                        component={MyProfilePage}
                    />
                    {/* <Route name="PointsCreate" exact path="/points/create" component={PointsCreatePage} /> */}
                    {/* <Route name="Points" exact path="/points" component={PointsPage} /> */}
                    {/* <Route name="Rewards" exact path="/rewards" component={RewardsPage} /> */}
                    {/* <Route name="UserProfile" exact path="/control-panel/users/:id" component={UserProfilePage} /> */}
                    {/* <Route name="Configurations" exact path="/configurations" component={Configurations} /> */}
                    {/* <Route name="ControlPanel" path="/control-panel/control-panel" component={ControlPanelPage} /> */}
                  </Switch>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;