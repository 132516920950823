import { fetchGet, fetchPut, fetchPost,jsonToQueryParam, fetchDelete} from './CommonServiceUtils';
import Constants from '../Constants';
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";
const BASE_URL = `${Constants.BASE_URL}coreservice/`;//`${Constants.BASE_URL}loyaltyservice2/`;

const getNotifications = (queryObj) => {
    return fetchGet(
        `${BASE_URL}notifications?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.NOTIFICATION,
            AccessPermissionModules[AccessPermissionModuleNames.NOTIFICATION]
                .actions.ListNotifications
        )
    );
};

const createNotifications = ({ templateId, ...rest }) => {
    return fetchPost(
        `${BASE_URL}notifications`, rest,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.NOTIFICATION,
            AccessPermissionModules[AccessPermissionModuleNames.NOTIFICATION]
                .actions.CreateNotification
        )
    );
};

const updateNotifications = (id, payload) => {
    return fetchPut(
        `${BASE_URL}notifications/${id}`, payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.NOTIFICATION,
            AccessPermissionModules[AccessPermissionModuleNames.NOTIFICATION]
                .actions.UpdateNotification
        )
    );
};

const deleteNotification = (id) => {
    return fetchDelete(
        `${BASE_URL}notifications/${id}`, 
        true,
        getPermissionPath(
            AccessPermissionModuleNames.NOTIFICATION,
            AccessPermissionModules[AccessPermissionModuleNames.NOTIFICATION]
                .actions.DeleteNotification
        )
    );
}

const getNotificationLogs= (notificationPayload) => {
    return fetchGet(
        `${BASE_URL}notificationlogs?${jsonToQueryParam(notificationPayload)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.NOTIFICATION,
            AccessPermissionModules[AccessPermissionModuleNames.NOTIFICATION]
                .actions.ListNotificationLogs
        )
    );
  };

export { 
    getNotifications, 
    createNotifications, 
    updateNotifications, 
    deleteNotification,
    getNotificationLogs
}