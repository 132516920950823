import { toTitleCase } from "./StringUtils";

const isEqualObjects = (a, b) => {
    let s = (o) =>
        o
            ? Object.entries(o)
                  .sort()
                  .map((i) => {
                      if (i[1] instanceof Object) i[1] = s(i[1]);
                      return i;
                  })
            : o;
    return JSON.stringify(s(a)) === JSON.stringify(s(b));
};

const isEmptyObject = (object) => {
    return Object.keys(object).length === 0;
};

const removeKeysFromObject = (obj, keys) => {
    console.debug(obj);
    return Object.entries(obj).reduce((result, [keyName, value]) => {
        if (!keys.includes(keyName)) {
            if (typeof value === "object" && !Array.isArray(value)) {
                result[keyName] = removeKeysFromObject(value, keys);
            } else {
                result[keyName] = value;
            }
        }
        return result;
    }, {});
    // for (var prop in obj) {
    //   if (obj.hasOwnProperty(prop)) {
    //     switch (typeof obj[prop]) {
    //       case "object":
    //         if (keys.indexOf(prop) > -1) {
    //           delete obj[prop];
    //         } else {
    //           removeKeysFromObject(obj[prop], keys);
    //         }
    //         break;
    //       default:
    //         if (keys.indexOf(prop) > -1) {
    //           delete obj[prop];
    //         }
    //         break;
    //     }
    //   }
    // }
};

const getValueFromObject = (object, path) => {
    return path.split(".").reduce((r, k) => r?.[k], object);
};

const objectToLabelValueArray = (items) =>
    Object.keys(items).map((item) => ({
        value: item,
        label: toTitleCase(items[item]),
    }));

export {
    isEqualObjects,
    isEmptyObject,
    removeKeysFromObject,
    getValueFromObject,
    objectToLabelValueArray,
};
