import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { round } from 'lodash';
import Constants from '../Constants';
import Mustache from 'mustache';
import { parseJson, handleErrors } from './CommonServiceUtils';
import { exportHtml } from '@shoutout-labs/shoutout-message-editor-enterprise/lib/EmailEditor';
import ReactDOMServer from 'react-dom/server';

const UTILITY_SERVICE_BASE_URL = 'https://api.getshoutout.com/utilityservice/'

const decodeToken = (token) => {
    return jwtDecode(token);
}

const isTokenTimeNotExpired = (exp) => {
    if (exp) {
        return moment.unix(exp - 10, 'x').isAfter(moment());//check 10 seconds ahead
    }

    return false;
}

const filtersMap = {
    'string': [
        {
            id: 1,
            name: 'is',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        },
        {
            id: 2,
            name: 'is not',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        },
        {
            id: 3,
            name: 'starts with',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        },
        {
            id: 4,
            name: 'is known',
            _inputVisible: false
        },
        {
            id: 6,
            name: 'is unknown',
            _inputVisible: false
        },

        {
            id: 5,
            name: 'contains',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        }
    ],
    'number': [
        {
            id: 9,
            name: 'equals',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        },
        {
            id: 10,
            name: 'greater than',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        },
        {
            id: 11,
            name: 'less than',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        },
        {
            id: 12,
            name: 'greater than or equal',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        },
        {
            id: 13,
            name: 'less than or equal',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        }
    ],
    'array': [
        {
            id: 7,
            name: 'has',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        },
        {
            id: 8,
            name: 'starts with',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        },
        {
            id: 17,
            name: 'contains',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        }
    ],
    'date': [
        {
            id: 1,
            name: 'is',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        },
        {
            id: 5,
            name: 'contains',
            _inputVisible: true,
            _inputPlaceholder: 'search key'
        },
        {
            id: 14,
            name: 'more than',
            _inputVisible: true,
            _inputPlaceholder: 'days ago'
        },
        {
            id: 15,
            name: 'less than',
            _inputVisible: true,
            _inputPlaceholder: 'days ago'
        }
    ]
};

const defaultColumnMap = {
    name: {
        order: 0,
        label: 'Name',
        type: 'string',
        visible: true
    },
    mobile_number: {
        order: 1,
        label: 'Mobile Number',
        type: 'string',
        visible: true
    },
    email: {
        order: 2,
        label: 'Email',
        type: 'string',
        visible: true
    },
    last_seen_on: {
        order: 3,
        label: 'Last Seen On',
        type: 'date',
        visible: true
    },
    tags: {
        order: 4,
        label: 'Tags',
        type: 'array',
        visible: true
    },
    user_id: {
        order: 5,
        label: 'User ID',
        type: 'string',
        visible: false
    }
};

const gsm7Encode = new RegExp("^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");

const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

const getIpInfo = () => {
    try {
        if (sessionStorage.getItem("countryCode")) {
            return Promise.resolve({ country: sessionStorage.getItem("countryCode") });
        } else {
            const countryCodeResponse = fetch(`${UTILITY_SERVICE_BASE_URL}ipinfo`, {
                method: 'GET'
            }).then(handleErrors).then(parseJson);
            const country = countryCodeResponse.data.country.toLowerCase();
            sessionStorage.setItem("countryCode", country);
            return Promise.resolve({ country });
        }

    } catch (e) {
        return Promise.resolve({ country: "lk" });
    }
}

const getFilters = (columnType) => {
    return filtersMap[columnType];
}

const getFilterById = (filterId) => {
    return Object.values(filtersMap).flat().find(item => item.id === filterId);
    // return chain(filtersMap).values().flatten().find({ id: filterId }).value();
}

const convertContactAttributeToReadable = (attribute) => {
    const split = attribute.replace(/^_/, '').split('_');
    split[0] = split[0].charAt(0).toUpperCase() + split[0].slice(1);
    return split.join(' ');
}

const getDefaultColumnMap = () => {
    return new Promise(resolve => {
        resolve(defaultColumnMap);
    });

}
const isGSMAlphabet = (text) => {
    return gsm7Encode.test(text);
}
const getSmsLength = (message) => {
    message = message.replace(/<<(.*?)>>/g, Constants.SHORT_URL_DOMAIN + '/xxxxxx');
    return message.length;
}

const getPercentage = (numerator = 0, denominator = 1) => {
    if (numerator === 0 && denominator === 0) {
        return 0;
    }
    let percentage = round((numerator / denominator) * 100);
    return percentage > 100 ? 100 : percentage;
}
const getDatesBetween = (fromDate, toDate, format = "YYYY-MM-DD") => {
    let currDate = moment(fromDate).startOf('day');
    let lastDate = moment(toDate).startOf('day');
    let dates = [moment(currDate.clone().toDate()).format(format)];
    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(moment(currDate.clone().toDate()).format(format));
    }
    dates.push(moment(lastDate.clone().toDate()).format(format));
    return dates;
}

const validateEmail = (email) => {
    return emailRegex.test(email);
}

const getCampaignStatus = (campaign) => {
    if (campaign.type.toUpperCase() === Constants.CAMPAIGN_TRIGGER) {
        if (campaign.progress && campaign.progress.charging === Constants.CAMPAIGN_PROGRESS_STATUS.FAILED) {

            return Constants.CAMPAIGN_STATUS.INSUFFICIENT_CREDITS;
        }
        return Constants.CAMPAIGN_STATUS.LISTENING;
    } else {

        if (campaign.progress) {

            switch (campaign.progress.reviewing) {
                case Constants.CAMPAIGN_PROGRESS_STATUS.FAILED: {
                    return Constants.CAMPAIGN_STATUS.REJECTED;
                }

                case Constants.CAMPAIGN_PROGRESS_STATUS.PENDING: {
                    return Constants.CAMPAIGN_STATUS.REVIEWING;
                }
                default: {

                }
            }

            switch (campaign.progress.building) {
                case Constants.CAMPAIGN_PROGRESS_STATUS.FAILED: {
                    return Constants.CAMPAIGN_STATUS.FAILED;
                }
                case Constants.CAMPAIGN_PROGRESS_STATUS.IN_PROGRESS: {
                    return Constants.CAMPAIGN_STATUS.CREATING;
                }
                case Constants.CAMPAIGN_PROGRESS_STATUS.PENDING: {
                    return Constants.CAMPAIGN_STATUS.CREATING;
                }
                default: {

                }
            }


            switch (campaign.progress.charging) {
                case Constants.CAMPAIGN_PROGRESS_STATUS.FAILED: {
                    return Constants.CAMPAIGN_STATUS.INSUFFICIENT_CREDITS;
                }
                case Constants.CAMPAIGN_PROGRESS_STATUS.IN_PROGRESS: {
                    return Constants.CAMPAIGN_STATUS.CREATING;
                }
                case Constants.CAMPAIGN_PROGRESS_STATUS.PENDING: {
                    return Constants.CAMPAIGN_STATUS.CREATING;
                }
                default: {

                }
            }

            switch (campaign.progress.sending) {
                case Constants.CAMPAIGN_PROGRESS_STATUS.FAILED: {
                    return Constants.CAMPAIGN_STATUS.FAILED;
                }
                case Constants.CAMPAIGN_PROGRESS_STATUS.IN_PROGRESS: {
                    return Constants.CAMPAIGN_STATUS.RUNNING;
                }
                case Constants.CAMPAIGN_PROGRESS_STATUS.PENDING: {
                    return Constants.CAMPAIGN_STATUS.SCHEDULED;
                }
                default: {
                    return Constants.CAMPAIGN_STATUS.FINISHED;
                }
            }
        } else {
            switch (campaign.status) {
                case 0:
                    return Constants.CAMPAIGN_STATUS.CREATING;

                case 1:
                    return Constants.CAMPAIGN_STATUS.RUNNING;

                case 2:
                    return Constants.CAMPAIGN_STATUS.FINISHED;

                case 3:
                    return Constants.CAMPAIGN_STATUS.LISTENING;

                case 4:
                    return Constants.CAMPAIGN_STATUS.FAILED;

                case 5:
                    return Constants.CAMPAIGN_STATUS.SCHEDULED;

                default:
                    return Constants.CAMPAIGN_STATUS.FAILED;

            }
        }
    }
}

const buildSms = (smsTemplate, contactData, additionalData = []) => {
    additionalData.forEach(({ url, shortenUrl }) => {
        if (url) {
            let urlRegEx = new RegExp("<<" + url + ">>", "gm");
            smsTemplate = smsTemplate.replace(urlRegEx, shortenUrl);
        }
    });
    return Mustache.render(smsTemplate, contactData);
}

const downloadCSV = (data, filename) => {
    return fetch("data:application/csv," + data)
        .then(function (res) { return res.blob(); })
        .then(function (blob) {
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                let link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    let url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }

        }).catch(err => {
            throw err;
        });
}

const cleanEmptyValuesInObject = (obj) => {
    Object.entries(obj).forEach(([key, val]) =>
        //eslint-disable-next-line
        (val && typeof val === 'object') && this.cleanEmptyValuesInObject(val) || (val === null || val === "") && delete obj[key]
    );
    return obj;
};

const calculateOptoutMessageLength = (senderId, campaignType) => {
    if (campaignType === "transactional") {
        return { message: "", length: 0 };
    }
    if (senderId.metadata?.usage) {//New sender Id format

        if (senderId.metadata.usage === "transactional") {
            return { message: "", length: 0 };
        }
        // Dedicated number
        if (senderId.metadata.unsubNumber) {
            let message;
            if (senderId.metadata.unsubKeyword) {
                message = Mustache.render(Constants.SMS_OPTOUT.TEMPLATE1, { mobileNumber: senderId.metadata.unsubNumber, keyword: senderId.metadata.unsubKeyword });

            }
            else if (senderId.metadata.unsubNumber !== senderId.senderId) {//Sender ID is not equal to the dedicated number
                message = Mustache.render(Constants.SMS_OPTOUT.TEMPLATE2, { mobileNumber: senderId.metadata.unsubNumber });

            }
            else {
                message = Mustache.render(Constants.SMS_OPTOUT.TEMPLATE3, { mobileNumber: senderId.metadata.unsubNumber });

            }
            return { message: message, length: message.length + 1 };

        }
        const countryLengthMap = senderId.metadata.countries?.reduce((result, country) => {

            const countryMatch = Constants.SMS_OPTOUT.COUNTRIES[country.toLowerCase()];
            if (countryMatch) {
                if (senderId.metadata.unsubNumber) {
                    countryMatch.mobileNumber = senderId.metadata.unsubNumber;
                }
                const message = Mustache.render(countryMatch.template, countryMatch);
                result.push({ message, length: message.length + 1 });//add extra character for line break
            }
            return result;
        }, []) || [];
        if (countryLengthMap.length > 0) {

            return countryLengthMap.sort((a, b) => b.length - a.length)[0];
        }
    }


    return { message: "", length: 0 };
}


const generatePointRuleDescription = (template, data) => {
    return Mustache.render(template, {
        ...data, pointRateCalc: function () {
            //return (1 / this.pointRate);
            return (this.pointRate);
        }
    });

}

const convertEditorStateToHtml = (editorState) => {
    return ReactDOMServer.renderToStaticMarkup(exportHtml(editorState));
}

const getDatesSeries = (startDate, stopDate) => {
    let dateArray = [];
    let currentDate = moment(startDate);
    let endDate = moment(stopDate);
    while (currentDate <= endDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
};





export {
    decodeToken, isTokenTimeNotExpired, getIpInfo, getFilters,
    calculateOptoutMessageLength, cleanEmptyValuesInObject, downloadCSV,
    buildSms, getCampaignStatus, validateEmail, getDatesBetween, getPercentage, getSmsLength,
    isGSMAlphabet, getDefaultColumnMap, getFilterById, convertContactAttributeToReadable, generatePointRuleDescription,
    convertEditorStateToHtml, getDatesSeries
};
