import { fetchGet,jsonToQueryParam} from './CommonServiceUtils';
import Constants from '../Constants';
const BASE_URL = `${Constants.BASE_URL}messageservice/`;

const getMessageLogs= (messageLogsPayload) => {
    return fetchGet(
        `${BASE_URL}messagelogs?${jsonToQueryParam(messageLogsPayload)}`,
        true,
    );
};
const exportMessageLogs= (messageLogsPayload) => {
    return fetchGet(
        `${BASE_URL}messagelogs/export?${jsonToQueryParam(messageLogsPayload)}`,
        true,
    );
};

export {
    getMessageLogs,
    exportMessageLogs,
}