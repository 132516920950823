import numeral from 'numeral';

numeral.nullFormat('N/A');

const percentageConverter = (total, amount) => {

    const percentageAmount = (Number(amount) / Number(total) * 100).toFixed(2);
    return percentageAmount;
};

const convertNumberFormat = (amount) => {
    return numeral(amount).format('0,0');
}

export {
    percentageConverter,
    convertNumberFormat
}