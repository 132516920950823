import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
    DraftJS,
    exportText,
} from "@shoutout-labs/shoutout-message-editor-enterprise";
import { UserContext } from "./userContext";
import { DataContext } from "./dataContext";
import { CampaignTypes, LaunchDates } from "Data";
import { convertEditorStateToHtml, createCampaign } from "Services";
import {
    buildMemberFilterConfig,
    convertTimeFrom12HTo24H,
    getDiffBetween2DatesInMinutes,
    getMongoDBQuery,
} from "Utils";

const { EditorState } = DraftJS;

const CreateCampaignContext = React.createContext();

const resetCampaignStatus = {
    campaignType: "",
    isCreating: "",
    campaignName: "",
    campaignDescription: "",
    from: "",
    to: [],
    selectSegments: "ALL",
    subject: "",
    selectLaunchDate: LaunchDates.NOW,
    launchDate: "",
    hours: "00",
    minutes: "00",
    timeOfDay: "",
    smsEditorState: null,
    emailEditorState: null,
    isNavigatedFromOutsideOfCampaignViews: false,
    appliedMemberFilters: [],
};

const initialState = {
    filterConfig: {},
    ...resetCampaignStatus,
};

const CreateCampaignContextActions = {
    SET_CAMPAIGN_TYPE: "setCampaignType",
    SET_ATTRIBUTE: "setAttribute",
    SET_TO_SEGMENTS: "setToSegments",
    SET_LAUNCH_DATE: "setLaunchDate",
    SET_EDITOR_STATE: "setEditorState",
    SET_IS_CREATING: "setIsCreating",
    SET_MEMBER_FILTER_CONFIG: "setMemberFilterConfig",
    SET_IS_NAVIGATED_FROM_OUTSIDE: "setIsNavigatedFromOutside",
    SET_APPLIED_MEMBER_FILTERS: "setAppliedMemberFilters",
    RESET: "reset",
};

const reducer = (state, action) => {
    switch (action.type) {
        case CreateCampaignContextActions.SET_CAMPAIGN_TYPE: {
            return {
                ...state,
                campaignType: action.campaignType,
            };
        }
        case CreateCampaignContextActions.SET_MEMBER_FILTER_CONFIG: {
            return {
                ...state,
                filterConfig: action.filterConfig,
            };
        }
        case CreateCampaignContextActions.SET_ATTRIBUTE: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case CreateCampaignContextActions.SET_LAUNCH_DATE: {
            return {
                ...state,
                launchDate: action.date,
            };
        }
        case CreateCampaignContextActions.SET_TO_SEGMENTS: {
            return {
                ...state,
                to: action.toSegments,
                ...(action.isSetAppliedMemberFilters
                    ? {
                        appliedMemberFilters: action.toSegments.map(
                            (tSeg) => tSeg?.filter || {}
                        ),
                    }
                    : {}),
            };
        }
        case CreateCampaignContextActions.SET_EDITOR_STATE: {
            switch (action.transport) {
                case CampaignTypes.SMS: {
                    return {
                        ...state,
                        smsEditorState: action.editorState,
                    };
                }
                case CampaignTypes.EMAIL: {
                    return {
                        ...state,
                        emailEditorState: action.editorState,
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case CreateCampaignContextActions.SET_IS_CREATING: {
            return {
                ...state,
                isCreating: action.status,
            };
        }
        case CreateCampaignContextActions.SET_IS_NAVIGATED_FROM_OUTSIDE: {
            return {
                ...state,
                isNavigatedFromOutsideOfCampaignViews: action.status,
            };
        }
        case CreateCampaignContextActions.SET_APPLIED_MEMBER_FILTERS: {
            return {
                ...state,
                appliedMemberFilters: action.appliedMemberFilters,
            };
        }
        case CreateCampaignContextActions.RESET: {
            return {
                ...state,
                ...resetCampaignStatus,
            };
        }
        default:
            return state;
    }
};

const getSegmentFilters = ({ appliedMemberFilters, filterConfig }) => {
    return appliedMemberFilters
        .map((aMF) =>
            aMF
                ? JSON.stringify(getMongoDBQuery(aMF, filterConfig))
                : JSON.stringify({})
        )
        .filter((item) => item);
};

const validateAndGetLaunchDateTime = (
    payload,
    { selectLaunchDate, launchDate, hours, minutes, timeOfDay }
) => {
    if (selectLaunchDate === LaunchDates.LATER) {
        if (!launchDate) {
            throw new Error("Launch date cannot be empty!");
        }

        if (!hours || !minutes || !timeOfDay) {
            throw new Error("Launch time cannot be empty!");
        }

        const launchDateToDate = new Date(launchDate);
        const timeOfDayIn24H = convertTimeFrom12HTo24H(
            `${hours}:${minutes} ${timeOfDay}`
        );
        const [hh, mm] = timeOfDayIn24H.split(":");
        const launchDateAndTime = new Date(
            launchDateToDate.getFullYear(),
            launchDateToDate.getMonth(),
            launchDateToDate.getDate(),
            hh,
            mm
        );
        const launchDateToNowDiff = getDiffBetween2DatesInMinutes(
            launchDateAndTime,
            new Date()
        );
        if (launchDateToNowDiff < 60) {
            throw new Error(
                `Launch date and time must be at least an hour ahead of the current date and time! Difference: ${launchDateToNowDiff} ${
                    ~[-1, 1].indexOf(launchDateToNowDiff) ? "minute" : "minutes"
                }.`
            );
        }

        payload = {
            ...payload,
            scheduleOn: launchDateAndTime.toISOString(),
        };
    } else {
        payload = {
            ...payload,
            scheduleOn: new Date().toISOString(),
        };
    }

    return payload;
};

const CreateCampaignContextProvider = (props) => {
    const {
        regionId,
        selectedRegion: { notificationConfiguration: regionNotificationConfig },
        organization: {
            configuration: { notificationConfiguration: orgNotificationConfig },
        },
    } = useContext(UserContext);
    const { contactAttributes, tags, tiers, affinityGroups } =
        useContext(DataContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();

    const setCampaignType = useCallback(
        (campaignType) =>
            dispatch({
                type: CreateCampaignContextActions.SET_CAMPAIGN_TYPE,
                campaignType,
            }),
        [dispatch]
    );

    const setMemberFilterConfig = useCallback(
        (filterConfig) => {
            dispatch({
                type: CreateCampaignContextActions.SET_MEMBER_FILTER_CONFIG,
                filterConfig,
            });
        },
        [dispatch]
    );

    const setToSegments = useCallback(
        (toSegments = [], isSetAppliedMemberFilters = true) =>
            dispatch({
                type: CreateCampaignContextActions.SET_TO_SEGMENTS,
                toSegments,
                isSetAppliedMemberFilters,
            }),
        [dispatch]
    );

    const setAttribute = useCallback(
        (event) => {
            const key = event.currentTarget.name || "";
            const value = event.currentTarget.value || "";

            if (key === "selectSegments" && value === "ALL") {
                setToSegments([], false);
            }

            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key,
                value,
            });
        },
        [setToSegments, dispatch]
    );

    const setLaunchDate = useCallback(
        (date) =>
            dispatch({
                type: CreateCampaignContextActions.SET_LAUNCH_DATE,
                date,
            }),
        [dispatch]
    );

    const setLaunchTime = useCallback(
        ({ key = "", value = "" }) =>
            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key,
                value,
            }),
        [dispatch]
    );

    const onChangeSmsEditor = useCallback(
        (editorState) =>
            dispatch({
                type: CreateCampaignContextActions.SET_EDITOR_STATE,
                editorState,
                transport: CampaignTypes.SMS,
            }),
        [dispatch]
    );

    const onChangeEmailEditor = useCallback(
        (editorState) =>
            dispatch({
                type: CreateCampaignContextActions.SET_EDITOR_STATE,
                editorState,
                transport: CampaignTypes.EMAIL,
            }),
        [dispatch]
    );

    const setIsNavigatedFromOutside = useCallback(
        (status) =>
            dispatch({
                type: CreateCampaignContextActions.SET_IS_NAVIGATED_FROM_OUTSIDE,
                status,
            }),
        [dispatch]
    );

    const setAppliedMemberFilters = useCallback(
        (appliedMemberFilters) =>
            dispatch({
                type: CreateCampaignContextActions.SET_APPLIED_MEMBER_FILTERS,
                appliedMemberFilters,
            }),
        [dispatch]
    );

    const reset = useCallback(
        () => dispatch({ type: CreateCampaignContextActions.RESET }),
        [dispatch]
    );

    const onCreateCampaign = useCallback(async () => {
        try {
            dispatch({
                type: CreateCampaignContextActions.SET_IS_CREATING,
                status: true,
            });
            const segmentFilters = getSegmentFilters({
                appliedMemberFilters: state.appliedMemberFilters,
                filterConfig: state.filterConfig,
            });

            let payload = {
                regionId,
                channel: state.campaignType,
                name: state.campaignName,
                description: state.campaignDescription,
                senderId: state.from,
                segmentIds: state.to.map((t) => t?.value),
                segmentFilters,
            };

            payload = validateAndGetLaunchDateTime(payload, {
                selectLaunchDate: state.selectLaunchDate,
                launchDate: state.launchDate,
                hours: state.hours,
                minutes: state.minutes,
                timeOfDay: state.timeOfDay,
            });

            payload = {
                ...payload,
                message: {
                    messageBody:
                        state.campaignType === CampaignTypes.SMS
                            ? exportText(state.smsEditorState)
                            : convertEditorStateToHtml(state.emailEditorState),
                    ...(state.campaignType === CampaignTypes.EMAIL
                        ? { messageSubject: state.subject }
                        : {}),
                },
            };

            await createCampaign(payload);
            toast.success(
                `Successfully created ${
                    state.campaignType === CampaignTypes.EMAIL ? "an" : "a"
                } ${state.campaignType?.toLowerCase()} campaign.`
            );
            const navigateTo = state.campaignType || CampaignTypes.SMS;
            reset();
            history.push(`/campaigns/${navigateTo?.toLowerCase()}`);
        } catch (e) {
            console.error(e);
            toast.error(
                <div>
                    {`Failed to create ${
                        state.campaignType === CampaignTypes.EMAIL ? "an" : "a"
                    } ${state.campaignType?.toLowerCase()} campaign!`}
                    <br />
                    {e.message
                        ? `Error: ${e.message}`
                        : "Please try again later."}
                </div>
            );
        } finally {
            dispatch({
                type: CreateCampaignContextActions.SET_IS_CREATING,
                status: false,
            });
        }
    }, [
        regionId,
        state.campaignName,
        state.campaignDescription,
        state.to,
        state.campaignType,
        state.appliedMemberFilters,
        state.from,
        state.selectLaunchDate,
        state.smsEditorState,
        state.emailEditorState,
        state.subject,
        state.filterConfig,
        state.launchDate,
        state.hours,
        state.minutes,
        state.timeOfDay,
        history,
        dispatch,
        reset,
    ]);

    useEffect(() => {
        if (regionNotificationConfig || orgNotificationConfig) {
            switch (state.campaignType) {
                case CampaignTypes.SMS:
                    dispatch({
                        type: CreateCampaignContextActions.SET_ATTRIBUTE,
                        key: "from",
                        value:
                            regionNotificationConfig?.smsConfiguration
                                ?.alphanumericSenderId ||
                            orgNotificationConfig?.smsConfiguration
                                ?.alphanumericSenderId ||
                            "",
                    });
                    break;
                case CampaignTypes.EMAIL:
                    dispatch({
                        type: CreateCampaignContextActions.SET_ATTRIBUTE,
                        key: "from",
                        value:
                            regionNotificationConfig?.emailConfiguration
                                ?.fromAddress ||
                            orgNotificationConfig?.emailConfiguration
                                ?.fromAddress ||
                            "",
                    });
                    break;
                default:
                    dispatch({
                        type: CreateCampaignContextActions.SET_ATTRIBUTE,
                        key: "from",
                        value: "",
                    });
            }
        } else {
            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key: "from",
                value: "",
            });
        }
    }, [orgNotificationConfig, regionNotificationConfig, state.campaignType]);

    useEffect(() => {
        onChangeSmsEditor(EditorState.createEmpty());
        onChangeEmailEditor(EditorState.createEmpty());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (Object.keys(contactAttributes).length > 0) {
            const config = buildMemberFilterConfig(
                {
                    ...contactAttributes,
                    "tier.tierId": {
                        label: "Tier",
                        type: "select",
                        fieldSettings: tiers.map((tier) => ({
                            title: tier.name,
                            value: tier._id,
                        })),
                    },
                    "affinityGroup.affinityGroupId": {
                        label: "Affinity Group",
                        type: "select",
                        fieldSettings: affinityGroups.map((affinityGroup) => ({
                            title: affinityGroup.name,
                            value: affinityGroup._id,
                        })),
                    },
                },
                tags || []
            );

            setMemberFilterConfig(config);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactAttributes, tags, affinityGroups, tiers]);

    const value = useMemo(
        () => ({
            ...state,
            setCampaignType,
            setAttribute,
            setToSegments,
            setLaunchDate,
            setLaunchTime,
            onChangeSmsEditor,
            onChangeEmailEditor,
            setMemberFilterConfig,
            setIsNavigatedFromOutside,
            setAppliedMemberFilters,
            reset,
            onCreateCampaign,
        }),
        [
            state,
            onChangeEmailEditor,
            onChangeSmsEditor,
            onCreateCampaign,
            reset,
            setAppliedMemberFilters,
            setAttribute,
            setCampaignType,
            setIsNavigatedFromOutside,
            setLaunchDate,
            setLaunchTime,
            setMemberFilterConfig,
            setToSegments,
        ]
    );

    return (
        <CreateCampaignContext.Provider value={value}>
            {props.children}
        </CreateCampaignContext.Provider>
    );
};

export { CreateCampaignContextProvider, CreateCampaignContext };
