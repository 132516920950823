const CardGenerateJobStatus = {
  PENDING : 'PENDING',
  PRINTING : 'PRINTING',
  PRINTED : 'PRINTED',
  DISPATCHED : 'DISPATCHED', 
  NEW_REQUEST : 'REQUESTED',
  COMPLETED : 'COMPLETED',
  FAILED : 'FAILED'
  };
  
  export default CardGenerateJobStatus;
  