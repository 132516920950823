import { fetchGet, jsonToQueryParam } from './CommonServiceUtils';
import Constants from '../Constants';

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/analytics/`;

const getTopListAnalytics = (tableURL,queryObjectTopRewards) => {
    return fetchGet(`${BASE_URL}${tableURL}?${jsonToQueryParam(queryObjectTopRewards)}`);
};
const exportAnalyticsReport =(filePath,exportQueryObject) => {
    return fetchGet(`${BASE_URL}${filePath}?${jsonToQueryParam(exportQueryObject)}`);
};

const getMemberRegistrationAnalyticsSeries = (queryObjectRegistration) => {
    return fetchGet(`${BASE_URL}members/registration/series?${jsonToQueryParam(queryObjectRegistration)}`);
};

const getMemberRegistrationAnalyticsCount = (queryObjectRegistration) => {
    return fetchGet(`${BASE_URL}members/registration/counts?${jsonToQueryParam(queryObjectRegistration)}`);
};

const getMemberAffinityGroupCount = (queryObjectAffinityGroup) => {
    return fetchGet(`${BASE_URL}members/affinitygroup/counts?${jsonToQueryParam(queryObjectAffinityGroup)}`);
};

const getMemberAffinityGroupSeries = (queryObjectAffinityGroup) => {
    return fetchGet(`${BASE_URL}members/affinitygroup/series?${jsonToQueryParam(queryObjectAffinityGroup)}`);
};

const getNewReturnMemberCount = (queryObjectReturnMember) => {
    return fetchGet(`${BASE_URL}members/new-return/counts?${jsonToQueryParam(queryObjectReturnMember)}`);
};

const getNewReturnMemberSeries = (queryObjectReturnMember) => {
    return fetchGet(`${BASE_URL}members/new-return/series?${jsonToQueryParam(queryObjectReturnMember)}`);
};

const getMemberTiersCount = (queryObjectTiers) => {
    return fetchGet(`${BASE_URL}members/tiers/counts?${jsonToQueryParam(queryObjectTiers)}`);
};

const getMemberTiersSeries = (queryObjectTiers) => {
    return fetchGet(`${BASE_URL}members/tiers/series?${jsonToQueryParam(queryObjectTiers)}`);
};

/* Redemptions Analytics */

const getTopRewardAnalytics = (queryObjectTopRewards) => {
    return fetchGet(`${BASE_URL}rewards/top?${jsonToQueryParam(queryObjectTopRewards)}`);
};

const getRedemptionCount = (queryObjectRedmptionCount) => {
    return fetchGet(`${BASE_URL}rewards/redeemedrewards/count?${jsonToQueryParam(queryObjectRedmptionCount)}`);
};

const getRedemptionSeries = (queryObjectRedemptionSeries) => {
    return fetchGet(`${BASE_URL}rewards/redeemedrewards/series?${jsonToQueryParam(queryObjectRedemptionSeries)}`);
};

/* Cards Analytics */

const getCardsAnalytics = (queryObject) => {
    return fetchGet(`${BASE_URL}cards/summary?${jsonToQueryParam(queryObject)}`);
};

const getCardsProcessingStatus  = (queryObject) => {
    return fetchGet(`${BASE_URL}cards/processingstatussummary?${jsonToQueryParam(queryObject)}`);
};
const getCardsActivationsummary  = (queryObject) => {
    return fetchGet(`${BASE_URL}cards/activationsummary?${jsonToQueryParam(queryObject)}`);
};
/* Merchant Analytics */

const MERCHANTS_URL = `${BASE_URL}merchants/`;

    const getMerchantAsSeries = ( {transactionType,queryObj}) => {
        return fetchGet(`${MERCHANTS_URL}transactions/${transactionType}/series?${jsonToQueryParam(queryObj)}`);
    };
    const getMerchantAsCount = ({transactionType,queryObj}) => {
        return fetchGet(`${MERCHANTS_URL}transactions/${transactionType}/counts?${jsonToQueryParam(queryObj)}`);
    };
    /* Collections */
        const MERCHANTS_COLLECTIONS_URL = `${MERCHANTS_URL}transactions/collections/`;

        const getMerchantCollectionAsCount = queryObj => {
            return fetchGet(`${MERCHANTS_COLLECTIONS_URL}counts?${jsonToQueryParam(queryObj)}`);
        };

        const getMerchantCollectionAsSeries = queryObj => {
            return fetchGet(`${MERCHANTS_COLLECTIONS_URL}series?${jsonToQueryParam(queryObj)}`);
        };

    /* Redemptions */
        const MERCHANTS_REDEMPTIONS_URL = `${MERCHANTS_URL}transactions/redemptions/`;

        const getMerchantRedemptionAsCount = queryObj => {
            return fetchGet(`${MERCHANTS_REDEMPTIONS_URL}counts?${jsonToQueryParam(queryObj)}`);
        };

        const getMerchantRedemptionAsSeries = queryObj => {
            return fetchGet(`${MERCHANTS_REDEMPTIONS_URL}series?${jsonToQueryParam(queryObj)}`);
        }

    /* Adjustments */
        const MERCHANTS_ADJUSTMENT_URL = `${MERCHANTS_URL}transactions/adjustments/`;

        const getMerchantAdjustmentAsCount = queryObj => {
            return fetchGet(`${MERCHANTS_ADJUSTMENT_URL}counts?${jsonToQueryParam(queryObj)}`);
        };

        const getMerchantAdjustmentAsSeries = queryObj => {
            return fetchGet(`${MERCHANTS_ADJUSTMENT_URL}series?${jsonToQueryParam(queryObj)}`);
        };

    const getMerchantPointmovementsAnalytics = queryObj => {
        return fetchGet(`${MERCHANTS_URL}pointmovements?${jsonToQueryParam(queryObj)}`);
    };


export {
    getMemberRegistrationAnalyticsSeries,
    getMemberRegistrationAnalyticsCount,
    getMemberAffinityGroupCount,
    getMemberAffinityGroupSeries,
    getMemberTiersCount,
    getMemberTiersSeries,
    getRedemptionCount,
    getRedemptionSeries,
    getTopRewardAnalytics,
    getCardsAnalytics,
    // getMerchantCollectionAnalytics,
    // getMerchantRedemptionAnalytics,
    // getMerchantAdjustmentAnalytics,
    // getMerchantPointmovementsAnalytics
    getMerchantPointmovementsAnalytics,
    getMerchantCollectionAsCount,
    getMerchantCollectionAsSeries,
    getMerchantRedemptionAsCount,
    getMerchantRedemptionAsSeries,
    getMerchantAdjustmentAsCount,
    getMerchantAdjustmentAsSeries,
    getMerchantAsSeries,
    getMerchantAsCount,
    getTopListAnalytics,
    getCardsProcessingStatus,
    getCardsActivationsummary,
    exportAnalyticsReport,
    getNewReturnMemberSeries,
    getNewReturnMemberCount
}


























// const getPointsRates = (queryObject) => {
//     return fetchGet(`${BASE_URL}pointsstats/rates?${jsonToQueryParam(queryObject)}`);
// };

// const getPointsCount = (queryObject) => {
//     return fetchGet(`${BASE_URL}pointsstats/counts?${jsonToQueryParam(queryObject)}`);
// };

// const getPointsSeries = (queryObject) => {
//     return fetchGet(`${BASE_URL}pointsstats/series?${jsonToQueryParam(queryObject)}`);
// };

// const getBranchWiseCollectedPoint = (queryObject) => {
//     return fetchGet(`${BASE_URL}pointsstats/collectdistributed?${jsonToQueryParam(queryObject)}`);
// };

// const getBranchWiseRedeemedPoint = (queryObject) => {
//     return fetchGet(`${BASE_URL}pointsstats/redeemdistributed?${jsonToQueryParam(queryObject)}`);
// };

// const getHeatMapStat = (queryObject) => {
//     return fetchGet(`${BASE_URL}salestats/heatmap?${jsonToQueryParam(queryObject)}`);
// };

// const getSalesSummary = (queryObject) => {
//     return fetchGet(`${BASE_URL}salestats/summary?${jsonToQueryParam(queryObject)}`);
// };

// const getRewardsCount = (timePeriod) => { // fromDate, toDate
//     return fetchGet(`${BASE_URL}rewardsstats/counts?${jsonToQueryParam(timePeriod)}`);
// };

// const getRewardsSeries = (timePeriod) => {
//     return fetchGet(`${BASE_URL}rewardsstats/series?${jsonToQueryParam(timePeriod)}`);
// };

// const getRewardRedemptionSeries = (timePeriod) => {
//     return fetchGet(`${BASE_URL}rewardsstats/redemptions?${jsonToQueryParam(timePeriod)}`);
// };

// const getTopRewards = (timePeriod) => {
//     return fetchGet(`${BASE_URL}rewardsstats/topusedrewards?${jsonToQueryParam(timePeriod)}`);
// };

// const getUserSummary = (timePeriod) => {
//     return fetchGet(`${BASE_URL}usersstats/summary?${jsonToQueryParam(timePeriod)}`);
// };

// const getUserCount = (timePeriod) => {
//     return fetchGet(`${BASE_URL}usersstats/counts?${jsonToQueryParam(timePeriod)}`);
// };

// const getUserDistribution = (queryObj) => {
//     return fetchGet(`${BASE_URL}usersstats/distributioncounts?${jsonToQueryParam(queryObj)}`);
// };

// const getUserDistributionSources = (queryObj) => {
//     return fetchGet(`${BASE_URL}usersstats/distributionseries?${jsonToQueryParam(queryObj)}`);
// };

// const getUserSeries = (timePeriod) => {
//     return fetchGet(`${BASE_URL}usersstats/series?${jsonToQueryParam(timePeriod)}`);
// };

// const getUserSeriesReturn = (timePeriod) => {
//     return fetchGet(`${BASE_URL}usersstats/seriesreturn?${jsonToQueryParam(timePeriod)}`);
// };


// export { getPointsRates,
//         getHeatMapStat,
//         getSalesSummary,
//         getPointsCount,
//         getPointsSeries,
//         getBranchWiseCollectedPoint,
//         getBranchWiseRedeemedPoint,
//         getRewardsCount,
//         getRewardsSeries,
//         getRewardRedemptionSeries,
//         getTopRewards,
//         //getDailyPeopleReports,
//         getUserSummary,
//         getUserCount,
//         getUserDistribution,
//         getUserSeries,
//         getUserDistributionSources,
//         getUserSeriesReturn,

//     };
