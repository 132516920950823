import { toTitleCase } from "Utils";

const CampaignTypes = {
    SMS: "SMS",
    EMAIL: "EMAIL",
};

const CampaignStatus = {
    CREATING: "CREATING",
    RUNNING: "RUNNING",
    FINISHED: "FINISHED",
    LISTENING: "LISTENING",
    FAILED: "FAILED",
    SCHEDULED: "SCHEDULED",
    PAUSED: "PAUSED",
    WAITING: "WAITING",
};

const CampaignsFilterOptions = {
    STATUS: "STATUS",
    TO_SMS: "TO_SMS",
    TO_EMAIL: "TO_EMAIL",
};

const CampaignStatusOptions = Object.keys(CampaignStatus).map((status) => ({
    label: toTitleCase(status),
    value: status,
}));

const LaunchDates = {
    NOW: "NOW",
    LATER: "LATER",
};

const ObjectKeyMap = {
    SMS: "smsHeaders",
    EMAIL: "emailHeaders",
};

const CampaignDetailsViewMainHeader = {
    SMS: "SMS Campaigns Details",
    EMAIL: "Email Campaigns Details",
};

const CampaignDetailsSectionTwo = {
    emailHeaders: {
        totalMessages: {
            label: "Total Messages",
            nextPointArrow: true,
            percentageKey: "deliveredPercentage",
        },
        deliveredMessages: {
            label: "Delivered Messages",
            nextPointArrow: true,
            percentageKey: "openedPercentage",
            extraRowKey: "bounce",
        },
        openedMessages: {
            label: "Opened Messages",
            nextPointArrow: true,
            percentageKey: "clickedPercentage",
            extraRowKey: "spam",
        },
        clickedMessages: { label: "Clicked Messages", nextPointArrow: false },
    },
    smsHeaders: {
        totalMessages: {
            label: "Total Messages",
            nextPointArrow: true,
            percentageKey: "validPercentage",
        },
        validMessages: {
            label: "Valid Messages",
            nextPointArrow: true,
            percentageKey: "successPercentage",
            extraRowKey: "invalid",
        },
        successMessages: {
            label: "Success Messages",
            nextPointArrow: true,
            percentageKey: "deliveredPercentage",
            extraRowKey: "failed",
        },
        deliveredMessages: {
            label: "Delivered Messages",
            nextPointArrow: true,
            percentageKey: "clickedPercentage",
        },
        clickedMessages: { label: "Clicked Messages", nextPointArrow: false },
    },
};

const CampaignDetailsSectionTwoColumnSize = {
    SMS: "flex-container-div-col-2",
    EMAIL: "flex-container-div-col-3",
};

export {
    CampaignTypes,
    CampaignStatus,
    CampaignsFilterOptions,
    CampaignStatusOptions,
    LaunchDates,
    ObjectKeyMap,
    CampaignDetailsViewMainHeader,
    CampaignDetailsSectionTwo,
    CampaignDetailsSectionTwoColumnSize,
};
