import React from "react";
import { toTitleCase } from "Utils";
import moment from "moment";
import CardGenerateJobStatus from "../../../Data/CardGenerateJobStatus";

const convertDataToPieChartFormat = (dataset, label, value = "count",valueTwo) => (
    dataset && dataset.length > 0 &&
        dataset.map(dataItem => ({
              name: toTitleCase(dataItem?.[label] ||dataItem?.tier|| "Unknown"),
              value:(valueTwo?(dataItem?.[value]+dataItem?.[valueTwo])||"0":dataItem?.[value]||"0"),
            })
        )
) || [];

const convertDataToVBarChartFormat = (dataset, properties = []) => (
    properties
        .map(el => ({
            key: el,
            value:
                dataset.reduce((preVal, currVal) => preVal.concat(currVal?.[el]), [])
        }))
        .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {})
);

const convertDataToTreeMapDiskChart = (dataset) => {
        const nodeOrder=[
            CardGenerateJobStatus.NEW_REQUEST,
            CardGenerateJobStatus.PENDING,
            CardGenerateJobStatus.PRINTING,
            CardGenerateJobStatus.COMPLETED
        ]
        const totalCount =parseInt(dataset.reduce((a, b) => a + (b.count || 0), 0))
        const data=[];
        const elements=[];
        nodeOrder.forEach((cardStatus,index)=>{
            dataset.forEach((value)=>{
                if(cardStatus===value.processingStatus){
                    data.push({
                        "id" : index+1,
                        name:value.processingStatus!== CardGenerateJobStatus.NEW_REQUEST? `${toTitleCase(value.processingStatus)} \n \n ${value.count} `:`New Requests  \n \n ${value.count}` ,
                        x: index*10,
                        y: 0,
                        value:value.count
                    })
                  if(index!==0) {
                      elements.push({
                          type: 'text',
                          left: `${330*index}px`,
                          top: '130px',
                          style: {
                              text: `${((value.count/totalCount)*100).toFixed(2)} %`,
                              fontSize: 10,
                              fontWeight: 'bold',
                              stroke: '#000',
                          },
                      })
                  }
                }
                }
            )
        })
       return {data,elements}
};

const getSumOfData = dataset => (
    dataset && dataset.length > 0 &&
        dataset.reduce((num1, num2) => num1 + num2, 0)
) || 0;


/*const getDatesInRange=(startDate, endDate,dataset,properties)=> {
    const date = new Date(startDate);
    const dateBucketKeyMap = (dataset.reduce((map, obj)=> {
        map[obj.dateBucketKey] = obj||"-";
        return map;
    }, {}))
    const dummyObject = properties.reduce((o, key) => Object.assign(o, {[key]: "0"}), {});
    const dates = [];
    while (date <= endDate) {
        if(dateBucketKeyMap[moment(date).format("YYYY-MM-DD")]){
            dates.push(dateBucketKeyMap[moment(date).format("YYYY-MM-DD")]);

        }else {
            dates.push({...dummyObject,dateBucketKey:moment(new Date(date)).format("YYYY-MM-DD")});
        }
        date.setDate(date.getDate() + 1);
    }
    return dates;
}*/

/*const getWeekInRange=(startDate, endDate,dataset,properties)=> {
    const week = new Date(startDate);
    const weekBucketKeyMap = (dataset.reduce((map, obj)=> {
        map[obj.dateBucketKey] = obj||"-";
        return map;
    }, {}))
    const dummyObject = properties.reduce((o, key) => Object.assign(o, {[key]: "0"}), {});
    const weeks = [];
    while (week <= endDate) {
        if(weekBucketKeyMap[moment(week).format("YYYY-W")]){
            weeks.push(weekBucketKeyMap[moment(week).format("YYYY-W")]);

        }else {
            weeks.push({...dummyObject,dateBucketKey:moment(new Date(week)).format("YYYY-W")});
        }
        week.setDate(week.getDate() + 7);
    }
    return weeks;
}*/
/*
const getMonthInRange=(startDate, endDate,dataset,properties)=> {
    let month = new Date(startDate);
    const monthBucketKeyMap = (dataset.reduce((map, obj)=> {
        map[obj.dateBucketKey] = obj||"-";
        return map;
    }, {}))

    const dummyObject = properties.reduce((o, key) => Object.assign(o, {[key]: "0"}), {});
    const months = [];
    while (month <= endDate) {
        if(monthBucketKeyMap[moment(month).format("YYYY-MM")]){
            months.push(monthBucketKeyMap[moment(month).format("YYYY-MM")]);

        }else {
            months.push({...dummyObject,dateBucketKey:moment(new Date(month)).format("YYYY-MM")});
        }
        month = new Date(moment(month).add(1, 'months'))
    }
    return months;
}
*/

/*const getQuarterInRange=(startDate, endDate,dataset,properties)=> {
    let quarter = new Date(startDate);
    const quarterBucketKeyMap = (dataset.reduce((map, obj)=> {
        map[obj.dateBucketKey] = obj||"-";
        return map;
    }, {}))

    const dummyObject = properties.reduce((o, key) => Object.assign(o, {[key]: "0"}), {});
    const months = [];
    while (quarter <= endDate) {
        if(quarterBucketKeyMap[moment(quarter).format("YYYY-Q")]){
            months.push(quarterBucketKeyMap[moment(quarter).format("YYYY-Q")]);

        }else {
            months.push({...dummyObject,dateBucketKey:moment(new Date(quarter)).format("YYYY-Q")});
        }
        quarter = new Date(moment(quarter).add(3, 'months'))
    }
    return months;
}*/

const getCalendarRange=(startDate, endDate,dataset,properties,format,unit,amount,visualFormat)=> {
    let rangeElement = startDate;
    const dateBucketKeyMap = (dataset.reduce((map, obj)=> {
        map[obj.dateBucketKey] = obj||"-";
        return map;
    }, {}))

    const dummyObject = properties.reduce((o, key) => Object.assign(o, {[key]: 0}), {});
    const range = [];
    while (rangeElement <= endDate) {
        if(dateBucketKeyMap[moment(rangeElement).format(`${format}`)]){
            range.push({...dateBucketKeyMap[moment(rangeElement).format(`${format}`)],dateBucketKey:moment(new Date(rangeElement)).format(`${visualFormat}`)});
        }else {
            range.push({...dummyObject,dateBucketKey:moment(new Date(rangeElement)).format(`${visualFormat}`)});
        }
        rangeElement = new Date(moment(rangeElement).add(`${amount}`, `${unit}`))
    }
    return range;
}

const sortAscendingOrder = ({dataset,fromDate,toDate,properties,dateBucket}) => {
/*    dataset.sort((date1, date2) => new Date(date1[`${properties[0]}`].toString()) - new Date(date2[`${properties[0]}`].toString()))*/
    let barChartData={}
    switch(dateBucket) {
        case "DAY":
            barChartData = convertDataToVBarChartFormat(getCalendarRange(new Date(fromDate),new Date(toDate),dataset,properties,"YYYY-MM-DD","days",1,"YYYY-MM-DD"), properties)
            break;
        case "WEEK":
            barChartData=convertDataToVBarChartFormat(getCalendarRange(new Date(fromDate),new Date(toDate),dataset,properties,"YYYY-W","days",7,"MMM-DD-YYYY"),properties)
            break;
        case "MONTH":
            barChartData=convertDataToVBarChartFormat(getCalendarRange(new Date(fromDate),new Date(toDate),dataset,properties,"YYYY-MM","months",1,"YYYY-MMM"),properties)
            break;
        case "QUARTER":
            barChartData=convertDataToVBarChartFormat(getCalendarRange(new Date(fromDate),new Date(toDate),dataset,properties,"YYYY-Q","months",3,"YYYY-[Q]Q"),properties)
            break;
        case "YEAR":
            barChartData=convertDataToVBarChartFormat(getCalendarRange(new Date(fromDate),new Date(toDate),dataset,properties,"YYYY","years",1,"YYYY"),properties)
            break;
        default:

    }

/*    if(dateBucket==="DAY"){
        const foundDateFrom = barChartData[`${properties[0]}`].some(date => date === fromDate);
        const foundToDate = barChartData[`${properties[0]}`].some(date => date === toDate);

        if(!foundDateFrom){
            barChartData[`${properties[0]}`].unshift(fromDate)
            for (let i = 1; i < properties.length; i++) {
                barChartData[`${properties[i]}`].unshift(0)
            }
        }
        if(!foundToDate){
            barChartData[`${properties[0]}`].push(toDate)
            for (let i = 1; i < properties.length; i++) {
                barChartData[`${properties[i]}`].push(0)
            }
        }
    }*/
    return barChartData
};

const subtractTwoDays=({selection})=>{
    const startDate = moment(selection.startDate, "DD-MM-YYYY");
    const endDate =moment(selection.endDate, "DD-MM-YYYY").add(1, 'days');
    const diff = endDate.diff(startDate);
    const diffDuration = moment.duration(diff);
    return{
        days:(diffDuration.years() ||diffDuration.weeks() || diffDuration.months()) ? 0 :diffDuration.days(),
        weeks:(diffDuration.years() || diffDuration.months()) ? 0: diffDuration.weeks(),
        months:diffDuration.years()? 0: diffDuration.months(),
        years:diffDuration.years(),
    }
}
const sortCaret = (order) => {
    return <span className={`${order ? `caret-4-${order}` : "order-4"}`}/>;
};
export {
    convertDataToPieChartFormat,
    convertDataToVBarChartFormat,
    getSumOfData,sortAscendingOrder,
    subtractTwoDays,
    sortCaret,
    convertDataToTreeMapDiskChart
};
