import Constants from "../Constants";

const getSmsLength = (message) => {
    message = message.replace(
        /<<(.*?)>>/g,
        Constants.SHORT_URL_DOMAIN + "/xxxxxx"
    );
    return message.length;
};

export { getSmsLength };
